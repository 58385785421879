import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`L’ascocarp pot fer fins a 20 cm d’alçada, constituït per un peu blanc ben desenvolupat irregularment buit, recorregut per costelles longitudinals i un capell prim, blanc o crema format per varis plecs o lòbuls molt ondulats en forma de sella de muntar. El peu està coronat per una mena de capell prim, en forma de sella de muntar, amb dos o tres lòbuls ondulats. Les espores són el·líptiques, llises, de 18-20 x 9-15 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      